import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { SupportComponent } from './components/support/support.component';


const routes: Routes = [
  {
    path: HomeComponent.routeName, // base route
    component: HomeComponent
  },
  {
    path: SupportComponent.routeName, // base route
    component: SupportComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
