<section class="contact container" id="contact" #contact>
    <div class="heading">
        <p class="description">Join me for a coffee</p>
        <h2 class="title">Contact</h2>
        <div class="animated-bar"></div>
    </div>
    <div class="maps-container">
        <agm-map [latitude]="lat" [zoom]="zoom" [longitude]="lng" [scrollwheel]="false" [styles]="styles" [gestureHandling]="'none'" [streetViewControl]="false">
            <agm-marker  [latitude]="lat" [longitude]="lng"></agm-marker>
        </agm-map>
    </div>
    <div class="inner">
        
        <div class="contact-content columns">

            <div class="contact-detail column col-6 col-md-12">

                <div class="company-info-container">
                    <h3>Contact Info</h3>
                    <p class="sm-info">Always available for some cool freelance work!</p>
                </div>

                <div class="inner-detail column">
                    <div class="item">
                        <div class="image-content">
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="svg-inline--fa fa-smile fa-w-16 fa-5x" data-icon="smile" data-prefix="fal" viewBox="0 0 496 512">
                                <path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 464c-119.1 0-216-96.9-216-216S128.9 40 248 40s216 96.9 216 216-96.9 216-216 216zm90.2-146.2C315.8 352.6 282.9 368 248 368s-67.8-15.4-90.2-42.2c-5.7-6.8-15.8-7.7-22.5-2-6.8 5.7-7.7 15.7-2 22.5C161.7 380.4 203.6 400 248 400s86.3-19.6 114.8-53.8c5.7-6.8 4.8-16.9-2-22.5-6.8-5.6-16.9-4.7-22.6 2.1zM168 240c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160 0c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32z"/>
                            </svg>
                        </div>
                        <div class="text-content">
                            <h6>Name</h6>
                            <span>Aaron Demir</span>
                        </div>
                    </div>
                    <div class="item">
                        <div class="image-content">
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="svg-inline--fa fa-code-branch fa-w-12 fa-5x" data-icon="code-branch" data-prefix="fal" viewBox="0 0 384 512">
                                <path fill="currentColor" d="M384 144c0-44.2-35.8-80-80-80s-80 35.8-80 80c0 39.2 28.2 71.8 65.5 78.7-.8 17.2-5 30.4-12.7 40-17.5 21.8-53.1 25.2-90.7 28.7-28.2 2.6-57.4 5.4-80.4 16.9-3.4 1.7-6.7 3.6-9.7 5.7V158.4c36.5-7.4 64-39.7 64-78.4 0-44.2-35.8-80-80-80S0 35.8 0 80c0 38.7 27.5 71 64 78.4v195.2C27.5 361 0 393.3 0 432c0 44.2 35.8 80 80 80s80-35.8 80-80c0-36.9-24.9-67.9-58.9-77.2 5-9.6 12.3-14.6 19-18 17.5-8.8 42.5-11.2 68.9-13.7 42.6-4 86.7-8.1 112.7-40.5 12.4-15.5 19-35.5 19.8-60.7C357.3 214 384 182.1 384 144zM32 80c0-26.5 21.5-48 48-48s48 21.5 48 48-21.5 48-48 48-48-21.5-48-48zm96 352c0 26.5-21.5 48-48 48s-48-21.5-48-48c0-26.4 21.4-47.9 47.8-48h.6c26.3.2 47.6 21.7 47.6 48zm187.8-241.5L304 192c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48c0 22.4-15.4 41.2-36.2 46.5z"/>
                            </svg>
                        </div>
                        <div class="text-content">
                            <h6>Github</h6>
                            <span><a href="https://github.com/S1nPur1ty">github.com/S1nPur1ty</a></span>
                        </div>
                    </div>
                    <div class="item">
                        <div class="image-content">
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="svg-inline--fa fa-envelope-open fa-w-16 fa-3x" data-icon="envelope-open" data-prefix="fal" viewBox="0 0 512 512">
                                <path fill="currentColor" d="M349.32 52.26C328.278 35.495 292.938 0 256 0c-36.665 0-71.446 34.769-93.31 52.26-34.586 27.455-109.525 87.898-145.097 117.015A47.99 47.99 0 000 206.416V464c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V206.413a47.989 47.989 0 00-17.597-37.144C458.832 140.157 383.906 79.715 349.32 52.26zM464 480H48c-8.837 0-16-7.163-16-16V206.161c0-4.806 2.155-9.353 5.878-12.392C64.16 172.315 159.658 95.526 182.59 77.32 200.211 63.27 232.317 32 256 32c23.686 0 55.789 31.27 73.41 45.32 22.932 18.207 118.436 95.008 144.714 116.468a15.99 15.99 0 015.876 12.39V464c0 8.837-7.163 16-16 16zm-8.753-216.312c4.189 5.156 3.393 12.732-1.776 16.905-22.827 18.426-55.135 44.236-104.156 83.148-21.045 16.8-56.871 52.518-93.318 52.258-36.58.264-72.826-35.908-93.318-52.263-49.015-38.908-81.321-64.716-104.149-83.143-5.169-4.173-5.966-11.749-1.776-16.905l5.047-6.212c4.169-5.131 11.704-5.925 16.848-1.772 22.763 18.376 55.014 44.143 103.938 82.978 16.85 13.437 50.201 45.69 73.413 45.315 23.219.371 56.562-31.877 73.413-45.315 48.929-38.839 81.178-64.605 103.938-82.978 5.145-4.153 12.679-3.359 16.848 1.772l5.048 6.212z"/>
                            </svg>
                        </div>
                        <div class="text-content">
                            <h6>Email</h6>
                            <span><a href="mailto:singular.purity@gmail.com?Subject=Hi">singular.purity@gmail.com</a></span>
                        </div>
                    </div>
                    <div class="item">
                        <div class="image-content">
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="svg-inline--fa fa-code fa-w-18 fa-5x" data-icon="code" data-prefix="fal" viewBox="0 0 576 512">
                                <path fill="currentColor" d="M228.5 511.8l-25-7.1c-3.2-.9-5-4.2-4.1-7.4L340.1 4.4c.9-3.2 4.2-5 7.4-4.1l25 7.1c3.2.9 5 4.2 4.1 7.4L235.9 507.6c-.9 3.2-4.3 5.1-7.4 4.2zm-75.6-125.3l18.5-20.9c1.9-2.1 1.6-5.3-.5-7.1L49.9 256l121-102.5c2.1-1.8 2.4-5 .5-7.1l-18.5-20.9c-1.8-2.1-5-2.3-7.1-.4L1.7 252.3c-2.3 2-2.3 5.5 0 7.5L145.8 387c2.1 1.8 5.3 1.6 7.1-.5zm277.3.4l144.1-127.2c2.3-2 2.3-5.5 0-7.5L430.2 125.1c-2.1-1.8-5.2-1.6-7.1.4l-18.5 20.9c-1.9 2.1-1.6 5.3.5 7.1l121 102.5-121 102.5c-2.1 1.8-2.4 5-.5 7.1l18.5 20.9c1.8 2.1 5 2.3 7.1.4z"/>
                            </svg>
                        </div>
                        <div class="text-content">
                            <h6>LinkedIn</h6>
                            <span><a href="https://www.linkedin.com/in/s1npur1ty">linkedin.com/in/s1npur1ty</a></span>
                        </div>
                    </div>
                </div>
                
            </div>
            
            <form class="column col-6 col-md-12" [formGroup]="form" (ngSubmit)="sendEmail()">

                <div class="toast" [class.toast-error]="statusCode === 0 || statusCode !== 202" [class.toast-success]="statusCode === 202" [class.show]="showToast">
                    {{ statusCode === 202 ? 'Message successfully sent!' : statusCode === 0 ? 'Please fill in all the fields in a correct order' : 'Server error, please try again later.'}}
                </div>
      
                <div class="form-group">
                    <input 
                        formControlName="name"
                        id="name" 
                        type="text" 
                        class="form-control"
                        placeholder="Your name" />
                </div>
               
                <div class="form-group">
                    <input 
                        formControlName="email"
                        id="email" 
                        type="text" 
                        class="form-control"
                        placeholder="Your e-mail address" />
                </div>

                <div class="form-group">
                    <input 
                        formControlName="subject"
                        id="subject" 
                        type="text" 
                        class="form-control"
                        placeholder="Subject" />
                </div>
               
                <div class="form-group">
                    <textarea 
                        formControlName="text"
                        id="text" 
                        type="text" 
                        class="form-control"
                        placeholder="Message">
                    </textarea>
                </div>
              
                <button class="btn btn-primary" type="submit" 
                    [class.loading]="sending" 
                    [class.btn-error]="statusCode !== 0 && statusCode !== 202" 
                    [class.btn-success]="messageSent" 
                    [class.disabled]="sending || messageSent || showToast">{{ !messageSent ? 'Send' : 'Message sent!' }}</button>
            </form>
        </div>

    </div>
</section>