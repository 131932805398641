<section class="about container" id="about" #about>
    <div class="heading">
        <p class="description">Power from within</p>
        <h2 class="title">My skills</h2>
        <div class="animated-bar"></div>
    </div>
    <div class="inner">
        
        <div class="columns col-12">
            <div *ngFor="let skill of skills" class="progress-item column col-6 col-sm-12">
                <div class="progress-text">
                    <div class="front">
                        <img src="/assets/images/skills/{{ skill.icon }}" [attr.alt]="skill.name">
                        <label>{{ skill.name }}</label>
                    </div>
                    <label>{{ skill.indication }}%</label>
                </div>
                <div class="bar bar-sm">
                    <div class="bar-item tooltip" [attr.data-tooltip]="skill.indication" role="progressbar" [style.width.%]="skill.indication" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
        </div>

    </div>
</section>