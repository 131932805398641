<footer>
    <a [routerLink]="['']">
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_1_30" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="80" height="80">
                <circle cx="40" cy="40" r="40" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_1_30)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16.3033C0 6.19465 8.17574 -1.99999 18.261 -1.99999L63.739 -1.99999C73.8243 -1.99999 82 6.19467 82 16.3033V67.4106C82 75.4681 75.4832 82 67.4442 82C63.5844 82 59.8827 80.4634 57.153 77.7281L28.9601 49.4769C25.8068 46.3171 20.4161 48.5555 20.4161 53.0247C20.4161 54.3562 20.9442 55.633 21.884 56.574L24.35 59.0431C32.8071 67.5108 26.8239 82 14.87 82H13.8401C6.19644 82 0 75.7892 0 68.1279L0 16.3033ZM61.5327 37.6167C61.5751 42.1237 56.1399 44.4103 52.9603 41.2232L20.7587 8.77598C19.2625 7.26829 17.7201 5.7392 16.4992 4.00097C15.5779 2.68919 15.6445 1.41041 20.5 0.500006C24 -3.49999 17.4835 -8.96186 27.9516 1.99437L60.0621 34.1049C60.9923 35.0373 61.5204 36.2982 61.5327 37.6167Z" fill="#2C8650"/>
            </g>
        </svg>
    </a>
</footer>