import { Component, OnInit } from '@angular/core';
import myCompanyInfo from '../../../assets/json/company.json';
import MapsStyle from '../../../assets/json/maps.style.json';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl, Validators} from '@angular/forms';;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  companyInfo = myCompanyInfo;
  styles = MapsStyle;

  lat: number = 51.9201837;
  lng: number = 4.4720061;
  zoom: number = 15;

  public sending = false;
  public statusCode = 0;
  public showToast = false;
  public messageSent = false;

  private readonly cloudUrl = 'https://us-central1-nullcoder-website.cloudfunctions.net/sendMail';

  form = new FormGroup({
    name    : new FormControl( '', Validators.required ),
    email   : new FormControl( '', [Validators.required, Validators.email] ),
    subject : new FormControl( '', Validators.required ),
    text    : new FormControl( '', Validators.required )
  });
  
  constructor( private httpClient: HttpClient ) { }

  ngOnInit(): void {}

  sendEmail() : void {

    if( this.form.status !== 'VALID' ){
      this.fireToast();

      return;
    }

    this.sending = true;

    const httpOptions = {  
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'Access-Control-Allow-Origin':'*',
      })
    };

    this.httpClient.post( this.cloudUrl, this.form.value, httpOptions )
      .toPromise()
      .then( (res: any) => {
        this.statusCode = res.statusCode;
        this.sending = false;
        this.messageSent = true;
        
        this.fireToast();
      } )
      .catch( (err) => {
        this.sending = false;
        this.statusCode = -1;

        this.fireToast();
      } )
  }

  fireToast() {
    this.showToast = true;

    setTimeout( () => {
      this.showToast = false;
      setTimeout( () => this.statusCode = 0, 500 );
    }, 2000 );
  }

}
