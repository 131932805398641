<section class="intro container" id="intro" #intro>
    <div id="particles"></div>
    <div class="inner">
        
        <h3 class="name">I am Aaron Demir</h3>
        <p class="description">
            A <span>Full Stack</span> Blockchain Developer
        </p>
        <div class="arrows"></div>

    </div>
    <div class="seperator"></div>
</section>