import { Component, OnInit } from '@angular/core';
import myWorkingExperiences from '../../../assets/json/working-experiences.json';
import myEducations from '../../../assets/json/education.json';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss']
})
export class ExperienceComponent implements OnInit {

  workingExperiences = myWorkingExperiences;
  educations = myEducations;

  constructor() { }

  ngOnInit(): void {
  }

}
