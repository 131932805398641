<section class="support">
    <div class="inner">

        <h2>Your support helps us grow</h2>
        <p>We hope to create many projects to come that will be completely add free with a bunch of features build in it. But, we can't do that without your support! Help us so that we can make your life just as easy as ours!</p>

        <h5>BTC</h5>
        <p class="wrap">1ALE8zH3vHp6FF3rTvjazpoMPUriQ6fVk3</p>

        <h5>ETH</h5>
        <p class="wrap">0xb00b59e87c73241c1815b8656eee1ac56081b4c3</p>

        <h5>Paypal</h5>
        <a target="_blank" href="https://paypal.me/singularguy" class="btn btn-primary">PayPal</a>

    </div>
</section>

<app-footer></app-footer>