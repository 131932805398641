import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ScrollUpdater } from 'src/app/services/scroll-updater.service';

declare let particlesJS: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  currentSection = 'intro-spy';

  sub: Subscription;

  constructor( public scrollUpdater: ScrollUpdater ) { }

  ngOnInit(): void {
    particlesJS.load( 'particles', 'assets/json/particlesjs-config.json', function() {} );

    this.sub = this.scrollUpdater.onReceive().subscribe( ( data: any ) => {
      this.currentSection = data;
    } );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  onSectionSelected( selectedSection: string ) {
    this.currentSection = selectedSection
  }

}
