import { Component, OnInit } from '@angular/core';
import { ScrollUpdater } from 'src/app/services/scroll-updater.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  static readonly routeName: string = '';
  currentMenuId = '';
  previousMenuId: string;
  sub: Subscription;

  constructor( public scrollUpdater: ScrollUpdater ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  onChange() {
    this.scrollUpdater.onShare( this.currentMenuId );
  }

}
