<app-sidebar></app-sidebar>

<div class="container" scrollSpyContent="DIV" [(sscCurrentContent)]="currentMenuId" (sscCurrentContentChange)="onChange()">
    <div id="intro-spy"><app-intro></app-intro></div>
    <div id="work-spy"><app-work></app-work></div>
    <div id="about-spy"><app-about></app-about></div>
    <div id="experience-spy"><app-experience></app-experience></div>
    <div id="contact-spy"><app-contact></app-contact></div>
    <app-footer></app-footer>
</div>

<div class="bottom-bar show-sm"></div>