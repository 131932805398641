<aside>
    <nav class="navbar">
        <ul class="navbar-nav">

            <li class="nav-item logo-container">
                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_1_30" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="80" height="80">
                    <circle cx="40" cy="40" r="40" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_1_30)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16.3033C0 6.19465 8.17574 -1.99999 18.261 -1.99999L63.739 -1.99999C73.8243 -1.99999 82 6.19467 82 16.3033V67.4106C82 75.4681 75.4832 82 67.4442 82C63.5844 82 59.8827 80.4634 57.153 77.7281L28.9601 49.4769C25.8068 46.3171 20.4161 48.5555 20.4161 53.0247C20.4161 54.3562 20.9442 55.633 21.884 56.574L24.35 59.0431C32.8071 67.5108 26.8239 82 14.87 82H13.8401C6.19644 82 0 75.7892 0 68.1279L0 16.3033ZM61.5327 37.6167C61.5751 42.1237 56.1399 44.4103 52.9603 41.2232L20.7587 8.77598C19.2625 7.26829 17.7201 5.7392 16.4992 4.00097C15.5779 2.68919 15.6445 1.41041 20.5 0.500006C24 -3.49999 17.4835 -8.96186 27.9516 1.99437L60.0621 34.1049C60.9923 35.0373 61.5204 36.2982 61.5327 37.6167Z" fill="#2C8650"/>
                </g>
                </svg>
            </li>

            <li scrollSpyMenu="intro-spy" [ssmCurrentContent]="currentSection" class="nav-item">
                <button class="nav-link">
                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="svg-inline--fa fa-torii-gate fa-w-16 fa-5x" data-icon="torii-gate" data-prefix="fal" viewBox="0 0 512 512">
                        <path fill="#FFFFFF" d="M480 160c17.67 0 32-14.33 32-32V0a303.17 303.17 0 01-135.55 32h-240.9A303.17 303.17 0 010 0v128c0 17.67 14.33 32 32 32h32v96H8c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h56v216c0 4.42 3.58 8 8 8h16c4.42 0 8-3.58 8-8V288h320v216c0 4.42 3.58 8 8 8h16c4.42 0 8-3.58 8-8V288h56c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8h-56v-96h32zM32 128V47.56C65.39 58.38 100.49 64 135.55 64h240.89c35.07 0 70.17-5.62 103.55-16.44V128H32zm64 32h144v96H96v-96zm320 96H272v-96h144v96z"/>
                    </svg>
                    <span class="link-text">Home</span>
                </button>
            </li>
    
            <li scrollSpyMenu="work-spy" [ssmCurrentContent]="currentSection" class="nav-item">
                <button class="nav-link">
                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="svg-inline--fa fa-dice-d6 fa-w-14 fa-5x" data-icon="dice-d6" data-prefix="fal" viewBox="0 0 448 512">
                        <path fill="#FFFFFF" d="M431.88 116.13L239.88 4.3a31.478 31.478 0 00-31.76 0l-192 111.84C6.15 121.94 0 132.75 0 144.46v223.09c0 11.71 6.15 22.51 16.12 28.32l192 111.83a31.478 31.478 0 0031.76 0l192-111.83c9.97-5.81 16.12-16.62 16.12-28.32V144.46c0-11.71-6.15-22.52-16.12-28.33zM224 32.08l175.75 102.86L224 237.48 47.83 134.7 224 32.08zM32.21 162.65L208 265.18v205.37L32 367.54l.21-204.89zM240 470.84V265.18l175.98-102.64-.21 205.67L240 470.84z"/>
                    </svg>
                    <span class="link-text">Work</span>
                </button>
            </li>
    
            <li scrollSpyMenu="about-spy" [ssmCurrentContent]="currentSection" class="nav-item">
                <button class="nav-link">
                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="svg-inline--fa fa-code-branch fa-w-12 fa-5x" data-icon="code-branch" data-prefix="fal" viewBox="0 0 384 512">
                        <path fill="#FFFFFF" d="M384 144c0-44.2-35.8-80-80-80s-80 35.8-80 80c0 39.2 28.2 71.8 65.5 78.7-.8 17.2-5 30.4-12.7 40-17.5 21.8-53.1 25.2-90.7 28.7-28.2 2.6-57.4 5.4-80.4 16.9-3.4 1.7-6.7 3.6-9.7 5.7V158.4c36.5-7.4 64-39.7 64-78.4 0-44.2-35.8-80-80-80S0 35.8 0 80c0 38.7 27.5 71 64 78.4v195.2C27.5 361 0 393.3 0 432c0 44.2 35.8 80 80 80s80-35.8 80-80c0-36.9-24.9-67.9-58.9-77.2 5-9.6 12.3-14.6 19-18 17.5-8.8 42.5-11.2 68.9-13.7 42.6-4 86.7-8.1 112.7-40.5 12.4-15.5 19-35.5 19.8-60.7C357.3 214 384 182.1 384 144zM32 80c0-26.5 21.5-48 48-48s48 21.5 48 48-21.5 48-48 48-48-21.5-48-48zm96 352c0 26.5-21.5 48-48 48s-48-21.5-48-48c0-26.4 21.4-47.9 47.8-48h.6c26.3.2 47.6 21.7 47.6 48zm187.8-241.5L304 192c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48c0 22.4-15.4 41.2-36.2 46.5z"/>
                    </svg>
                    <span class="link-text">About</span>
                </button>
            </li>

            <li scrollSpyMenu="experience-spy" [ssmCurrentContent]="currentSection" class="nav-item">
                <button class="nav-link">
                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="svg-inline--fa fa-scroll fa-w-20 fa-5x" data-icon="scroll" data-prefix="fal" viewBox="0 0 640 512">
                        <path fill="#FFFFFF" d="M608 352h-64V112C544 50.25 493.75 0 432 0H80C35.88 0 0 35.89 0 80v80c0 17.64 14.34 32 32 32h96v224c0 51.11 40.25 92.62 90.66 95.46v.54h312C590.94 512 640 462.95 640 402.67V384c0-17.64-14.34-32-32-32zM128 160H32V80c0-26.47 21.53-48 48-48s48 21.53 48 48v80zm160 256c0 35.3-28.72 64-64 64s-64-28.7-64-64V80c0-18-5.97-34.62-16.03-48H432c44.12 0 80 35.89 80 80v240H320c-17.66 0-32 14.36-32 32v32zm320-13.33c0 42.64-34.69 77.33-77.34 77.33H295.03c15.35-17.02 24.97-39.32 24.97-64v-32h288v18.67z"/>
                    </svg>
                    <span class="link-text">Experiences</span>
                </button>
            </li>
    
            <li scrollSpyMenu="contact-spy" [ssmCurrentContent]="currentSection" class="nav-item">
                <button class="nav-link">
                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="svg-inline--fa fa-mug-hot fa-w-16 fa-5x" data-icon="mug-hot" data-prefix="fal" viewBox="0 0 512 512">
                        <path fill="#FFFFFF" d="M416 192.1H32c-17.7 0-32 14.3-32 32V416c0 53 43 96 96 96h192c53 0 96-43 96-96v-32h32c52.9 0 96-43 96-96s-43.1-95.9-96-95.9zM352 416c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V224.1h320V416zm64-64h-32V224h32c35.3 0 64 28.7 64 64s-28.7 64-64 64zM191.3 78.5c17.3 17.2 30.4 40.7 32.2 73.4.2 4.3 3.7 7.8 8 7.8h16c4.5 0 8.2-3.7 8-8.2-2.1-42.1-19.3-73.3-41.6-95.5-18.4-18.7-21.1-38.2-21.9-48.9-.3-4.1-3.9-7.1-8-7.1l-16 .1c-4.7 0-8.2 4-7.9 8.7.9 14.9 5.2 43.6 31.2 69.7zm-95.6 0c17.3 17.2 30.4 40.7 32.2 73.4.2 4.3 3.7 7.8 8 7.8h16c4.5 0 8.2-3.7 8-8.2-2.1-42.1-19.3-73.3-41.6-95.5-18.3-18.7-21-38.2-21.8-48.9C96.2 3 92.6 0 88.4 0l-16 .1c-4.7 0-8.2 4-7.9 8.7 1 14.9 5.2 43.6 31.2 69.7z"/>
                    </svg>
                    <span class="link-text">Contact</span>
                </button>
            </li>

            <li class="nav-item">
                <p class="copyright"><span class="copyright-text">☩</span>2023 Null Coder</p>
            </li>
    
        </ul>
      </nav>

</aside>