import { Component, OnInit } from '@angular/core';
import myWorks from '../../../assets/json/works.json';

import Shuffle from 'shufflejs';

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.scss']
})
export class WorkComponent implements OnInit {

  works = myWorks;
  shuffleInstance: any;
  selectedGroup = '';

  constructor() { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit() {
    this.shuffleInstance = new Shuffle(document.getElementById('portfolio-grid'), {
      itemSelector: '.single-item',
      sizer: '.js-shuffle-sizer'
    });
  }

  filterByGroup( group: string ){
    this.selectedGroup = group
    this.shuffleInstance.filter( group );
  }

}