<section class="experience container" id="experience" #experience>
    <div class="heading">
        <p class="description">Showcasing my experiences</p>
        <h2 class="title">Experiences</h2>
        <div class="animated-bar"></div>
    </div>
    <div class="inner">
        
        <div class="docs-content columns px-2">
            <h3 class="experience-title">Work</h3>
            <div class="experience-content columns col-12">
                <div *ngFor="let workExperience of workingExperiences" class="column col-12 working-experience-item item">
                    <div class="card">
                        <div class="card-header">
                            <div>
                                <h5>{{ workExperience.role }}</h5>
                                <div class="experience">{{ workExperience.started }} - {{ workExperience.ended }}</div>
                            </div>

                            <div>
                                <img class="company-logo" src="/assets/companies/{{ workExperience.icon }}" [attr.alt]="workExperience.name">
                            </div>
                        </div>
                        <div class="card-body" [innerHtml]="workExperience.description"></div>
                        <div class="card-footer">
                            {{ workExperience.company }} <span class="green-splitter">|</span> {{ workExperience.place !== '' ? workExperience.place + "," : "" }} 
                            {{ workExperience.country !== '' ? workExperience.country : "" }}
                        </div>
                    </div>
                </div>
            </div>
            <!-- <h3 class="education-title">Education</h3>
            <div class="education-container columns col-12">
                <div *ngFor="let education of educations" class="column col-6 col-md-12 education-item item">
                    <div class="card">
                        <div class="card-header">
                            <h5>{{ education.field }}</h5>
                            {{ education.school }} | {{ education.started }} - {{ education.ended }}
                        </div>
                        <div class="card-footer">
                            {{ education.degree }}
                        </div>
                    </div>
                </div>
            </div> -->
        </div>

    </div>
</section>