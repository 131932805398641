<section class="work container" id="work" #work>
    <div class="heading">
        <p class="description">Singular achievements</p>
        <h2 class="title">Work</h2>
        <div class="animated-bar"></div>
    </div>
    <div class="inner">
      
        <div class="columns col-12">
          <!-- Filter nav-->
          <div class="col-12">
            <ul class="list-inline filter-control" role="group" aria-label="Filter Control">
              <li class="list-inline-item" [class.tab-active]="selectedGroup === ''" (click)="filterByGroup( '' )">All</li>
              <li class="list-inline-item" [class.tab-active]="selectedGroup === 'web'" (click)="filterByGroup( 'web' )">Web</li>
              <li class="list-inline-item" [class.tab-active]="selectedGroup === 'app'" (click)="filterByGroup( 'app' )">App</li>
              <li class="list-inline-item" [class.tab-active]="selectedGroup === 'blockchain'" (click)="filterByGroup( 'blockchain' )">Blockchain</li>
            </ul>
          </div>
        </div>
        <!-- Thumbnail list-->
        <div id="portfolio-grid" class="portfolio-grid columns col-12" >
          <!-- Single item-->
          <div *ngFor="let work of works" class="single-item col-3 col-lg-4 col-sm-6" attr.data-groups='{{work.platforms | json}}'>
            <a class="portfolio-item" href="/assets/images/portfolio/{{work.image}}">
              <div class="portfolio-wrapper">
                <img class="img-fluid img-responsive" alt="Item" src="/assets/images/portfolio/{{work.image}}" />
                <div class="item-content">
                  <h6 class="content-title">{{ work.name }}</h6><span class="content-more">More Info</span>
                </div>
              </div>
            </a>
          </div>
        </div>

    </div>
</section>